import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function thanksPage () {
  return (
    <Layout>

    <section className="common">
      <div className="container">
        <div className="row">

            <div className="col-xl-6 offset-xl-3 col-lg-12 col-md-12 col-12 text-center">
                <br/><br/><br/>
                <h1>Thank you for your application!</h1>
            </div>
            
        </div>
      </div>
    </section>

  </Layout>

  )
}

export const Head = () => <Seo title="iamcore | Thank you for application!" noindex="yes"/>

export default thanksPage
